import { InstanceOptions, Tabs, TabsOptions } from 'flowbite';

import { Injectable } from '@angular/core';

@Injectable()
export class TabService {

  constructor() { }


  configTabs(tabsElementId: string, tabElements: { id: string, triggerEl: string, targetEl: string }[], options?: TabsOptions, instanceOptions?: InstanceOptions) {
    const tabsElement = document.getElementById(tabsElementId)!;
    const tabElementsWithDom = tabElements.map(tab => ({
      id: tab.id,
      triggerEl: document.getElementById(tab.triggerEl)!,
      targetEl: document.getElementById(tab.targetEl)!
    }));

    // options with default values
    const defaultOptions: TabsOptions = {
      defaultTabId: tabElementsWithDom[0].id,
      activeClasses:
        'text-primary-600 hover:text-primary-600 dark:text-primary-500 dark:hover:text-primary-400 border-primary-600 dark:border-primary-500',
      inactiveClasses:
        'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
      onShow: (res) => {
        
      },
    };

    // instance options with default values
    const defaultInstanceOptions: InstanceOptions = {
      id: tabsElementId,
      override: true,
    };

    // Merge provided options with default options
    const mergedOptions = { ...defaultOptions, ...options };
    const mergedInstanceOptions = { ...defaultInstanceOptions, ...instanceOptions };

    // Create the Tabs instance dynamically
    const tabs = new Tabs(
      tabsElement,
      tabElementsWithDom,
      mergedOptions,
      mergedInstanceOptions,
    );
    

    // open tab item based on defaultTabId or the first tab if not provided
    tabs.show(mergedOptions.defaultTabId || tabElementsWithDom[0].id);

    return tabs;
  }
}